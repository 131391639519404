import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { ROUTES } from "../../navigators/Routes";
import Loader from "../../components/Loader";

import {
  deleteProduct,
  getProducts,
  getProductSummary,
} from "../../controller/ProductsController";

import RenderLottie from "../../components/RenderLottie";
import { Animations } from "../../animations";

import { colors } from "../../themes/colors";

import { toast } from "../../utils/helper";

import { images } from "../../assets";

import ActionPopup from "../../components/ActionPopup";
import Button from "../../components/Button";
import FilterButton from "../../components/Filterbutton/FilterButton";
import ViewProducts from "../../components/ViewProducts/ViewProducts";
import TotalDetails from "../../components/TotalDetails/TotalDetails";

const Products = () => {
  const navigate = useNavigate();

  const [deletePopupVisible, setDeletePopupVisible] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [isSomethingWentWrong, setIsSomethingWentWrong] = useState(false);
  const [DeleteId, setDeleteId] = useState();
  const [outOfStock, setoutOfStock] = useState();
  const [stockInHand, setstockInHand] = useState();
  const [totalRetailValue, settotalRetailValue] = useState();

  const getScreenData = useCallback(async () => {
    try {
      setScreenLoading(true);
      await getAllProducts();
      let response = await getProductSummary();
      setoutOfStock(response.data.outOfStock);
      setstockInHand(response.data.stockInHand);
      settotalRetailValue(response.data.totalRetailValue);
    } catch (error) {
      console.log("Error in getScreenData :>> ", error);
      setIsSomethingWentWrong(true);
    } finally {
      setScreenLoading(false);
    }
  }, []);

  useEffect(() => {
    getScreenData();
  }, [getScreenData]);

  const getAllProducts = async () => {
    try {
      const response = await getProducts();

      console.log("response of products :>> ", response);

      if (response?.statusCode === 400) {
        // setIsSomethingWentWrong(true);
        setProductsData([]);
        return;
      }

      // setIsSomethingWentWrong(false);
      setProductsData(response?.data);
    } catch (error) {
      console.log("Error in getAllProducts :>> ", error);
      // setIsSomethingWentWrong(true);
      setProductsData([]);
    }
  };

  const onAddNewProduct = () => {
    navigate(ROUTES.ADD_EDIT_PRODUCT);
  };
  const onEditProduct = (id: any) => {
    navigate(ROUTES.ADD_EDIT_PRODUCT, { state: { value: true, id: id } });
  };

  const onDelete = async () => {
    try {
      // const deleteProductsPromise = selectedRows.map((row) => {
      // 	return deleteProduct(row);
      // });

      const response = await deleteProduct(DeleteId);

      console.log("response :>> ", response);

      if (response.some((res: any) => res?.statusCode === 400)) {
        toast.error("Error in deleting product");
        return;
      }

      toast.success("Product deleted successfully");
      hideDeletePopup();
      getScreenData();
    } catch (error) {
      console.log("error in deleting product :>> ", error);
    } finally {
      // setLoading(false);
    }
  };

  const handledelete = (id: any) => {
    setDeletePopupVisible(true);
    setDeleteId(id);
  };

  const hideDeletePopup = () => {
    setDeletePopupVisible(false);
  };

  return screenLoading ? (
    <Loader />
  ) : (
    <div>
      {isSomethingWentWrong ? (
        <RenderLottie
          animationData={Animations.SomethingWentWrong}
          title="Something Went Wrong"
          message="Please try again later or contact support for assistance."
        />
      ) : (
        <div className="p-3">
          <h4 className="mb-4 fw-bold">Products</h4>

          {/* Total Details */}
          <TotalDetails
            Items={[
              {
                backgroundcolor: colors.aqua,
                img: images.ReadyProduct,
                title: "Stock in hand",
                value: stockInHand,
              },
              {
                backgroundcolor: colors.lightOrange,
                img: images.Outofstock,
                title: "Out of stock",
                value: outOfStock,
              },
              {
                backgroundcolor: colors.purple,
                img: images.Stocks,
                title: "Total Retail Value",
                value: `₹ ${totalRetailValue}`,
              },
            ]}
          />

          {/* Search,Filter,and Add button */}
          <div className="d-flex justify-content-between align-items-center	my-5">
            <div className="search-container" style={{ width: "35%" }}>
              <input
                type="search"
                className="search-input outline-none border rounded-3 p-2 px-3"
                placeholder="Search Product"
              />
              <FontAwesomeIcon
                icon={faSearch}
                color="#9F9F9F"
                className="mr-2 search-icon"
              />
            </div>

            <div className="flex">
              <FilterButton
                Name={"Filter"}
                titles={["By Category", "By SubCategory", "By Brand"]}
              />
              <Button variant="dark" onClick={onAddNewProduct} className="ml-3">
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add Product
              </Button>
            </div>
          </div>

          {/* Products details*/}

          <div className="p-3 pt-0">
            {productsData.length > 0 ? (
              <ViewProducts
                data={productsData}
                onDelete={handledelete}
                onEdit={onEditProduct}
              />
            ) : (
              <RenderLottie
                animationData={Animations.NoDataFound}
                title="No Products Found"
                message="Please add some products"
              />
            )}
          </div>
        </div>
      )}

      <ActionPopup
        visible={deletePopupVisible}
        title=""
        message="Do you want to delete the product?"
        confirmText="Yes"
        cancelText="No"
        onConfirm={onDelete}
        onCancel={hideDeletePopup}
      />
    </div>
  );
};

export default Products;
