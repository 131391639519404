import { Table } from "react-bootstrap";
import "./style.css";

const BillsTable = () => {
  const transactions = [
    {
      amount: "₹1,200",
      balanceDue: "0",
      date: "09 Aug 2024",
      updatedTime: "10 Aug 2024, 03:50 PM",
      billNo: "1542100011",
      poNo: "011202",
      vendor: "24 Mantra Organic",
      paymentMode: "Cash",
      orderStatus: "On Process",
      deliveryDate: "12 Nov 2024",
      paymentStatus: "Pending",
      dueDate: "30 Nov 2024",
    },
    {
      amount: "₹990",
      balanceDue: "700",
      date: "12 July 2024",
      updatedTime: "15 July 2024, 12:34 PM",
      billNo: "2542100210",
      poNo: "011202",
      vendor: "Organic Tattva",
      paymentMode: "Cash",
      orderStatus: "Received",
      deliveryDate: "12 Nov 2024",
      paymentStatus: "Paid",
      dueDate: "30 Nov 2024",
    },
    {
      amount: "₹6,650",
      balanceDue: "1500",
      date: "12 July 2024",
      updatedTime: "15 July 2024, 12:34 PM",
      billNo: "2542100210",
      poNo: "011202",
      vendor: "Organic Tattva",
      paymentMode: "Cash",
      orderStatus: "Delayed",
      deliveryDate: "12 Nov 2024",
      paymentStatus: "Draft",
      dueDate: "30 Nov 2024",
    },
  ];

  const getStatusBadge = (status: string) => {
    switch (status) {
      case "On Process":
        return (
          <div className="p-2 px-4 onProcess rounded-3 fw-bold text-xs text-center w-9">
            On Process
          </div>
        );
      case "Received":
        return (
          <div className="p-2 px-4 success rounded-3 fw-bold text-xs text-center w-9">
            Received
          </div>
        );
      case "Delayed":
        return (
          <div className="p-2 px-4 delay rounded-3 fw-bold text-xs text-center w-9">
            Delayed
          </div>
        );
      case "Cancelled":
        return (
          <div className="p-2 px-4 delay rounded-3 fw-bold text-xs text-center w-9">
            Cancelled
          </div>
        );
      default:
        return (
          <div className="p-2 px-4 onProcess rounded-3 fw-bold text-xs text-center w-9">
            {status}
          </div>
        );
    }
  };

  const getPaymentBadge = (status: string) => {
    switch (status) {
      case "Pending":
        return (
          <div className="p-2 px-4 pending rounded-3 fw-bold text-xs text-center w-9">
            Pending
          </div>
        );
      case "Paid":
        return (
          <div className="p-2 px-4 success rounded-3 fw-bold text-xs text-center w-9">
            Paid
          </div>
        );
      case "Overdue":
        return (
          <div className="p-2 px-4 delay rounded-3 fw-bold text-xs text-center w-9">
            Overdue
          </div>
        );
      case "Draft":
        return (
          <div className="p-2 px-4 draft rounded-3 fw-bold text-xs text-center w-9">
            Draft
          </div>
        );
      default:
        return (
          <div className="p-2 px-4 onProcess rounded-3 fw-bold text-xs text-center w-9">
            {status}
          </div>
        );
    }
  };

  return (
    <div className="table-container">
      <Table className="mt-2 b-table transparent-table">
        <thead className={`bg-light`}>
          <tr>
            <th>Amount (₹)</th>
            <th>Balance Due (₹)</th>
            <th>
              Date/<span className="fw-normal">Updated Time</span>
            </th>
            <th>Bill No #</th>
            <th>Vendor Name</th>
            <th>Payment Mode</th>
            <th className="text-center">Order Status</th>
            <th className="text-center">Payment Status</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <tr key={index}>
              <td className="text-lg fw-bold">{transaction.amount}</td>
              <td className="text-lg text-center">{transaction.balanceDue}</td>
              <td>
                {transaction.date}
                <br />
                <div className="w-7 text-xs text-secondary">
                  {transaction.updatedTime}
                </div>
              </td>
              <td>
                {transaction.billNo}
                <br />
                <div className="w-7 text-xs text-secondary">
                  PO No: {transaction.poNo}
                </div>
              </td>
              <td>{transaction.vendor}</td>
              <td>{transaction.paymentMode}</td>
              <td>
                <div className="flex flex-column align-items-center text-xs text-center text-secondary">
                  {getStatusBadge(transaction.orderStatus)}
                  <div className="w-6">
                    Delivery Date: {transaction.deliveryDate}
                  </div>
                </div>
              </td>
              <td>
                <div className="flex flex-column align-items-center text-xs text-center text-secondary">
                  {getPaymentBadge(transaction.paymentStatus)}
                  <div className="w-6">Due Date: {transaction.dueDate}</div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default BillsTable;
