import { useNavigate } from "react-router-dom";

import { Dropdown } from "react-bootstrap";

import { ROUTES } from "../../navigators/Routes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faUsers } from "@fortawesome/free-solid-svg-icons";

interface HeaderProps {
  title: string;
  buttonName: String;
}

const BillHeader = (props: HeaderProps) => {
  const navigate = useNavigate();
  const { title, buttonName } = props;

  const handlePurchaseOrderClick = () => {
    navigate(ROUTES.NEW_PURCHASE_ORDERS, {
      state: { title: "New Bill", type: "PO" },
    });
  };

  const handleCustomerClick = () => {
    navigate(ROUTES.NEW_PURCHASE_ORDERS, {
      state: { title: "New Bill", type: "Customer" },
    });
  };
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div>
        <h4 style={{ fontWeight: "600" }}>{title}</h4>
      </div>
      <div>
        <Dropdown>
          <Dropdown.Toggle variant="dark" id="dropdown-basic">
            {buttonName}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              className="flex gap-3 align-items-center"
              onClick={handlePurchaseOrderClick}
            >
              <FontAwesomeIcon icon={faCartShopping} size="sm" /> For Purchase
              Order
            </Dropdown.Item>
            <Dropdown.Item
              className="flex gap-3 align-items-center"
              onClick={handleCustomerClick}
            >
              <FontAwesomeIcon icon={faUsers} size="sm" />
              For Customers
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default BillHeader;
