import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

interface HeaderProps {
  title: string;
  onButtonClick: () => void;
  buttonName: String;
}

const POHeader = (props: HeaderProps) => {
  const { title, onButtonClick, buttonName } = props;

  return (
    <div className="pt-3 pb-2 px-4 d-flex align-items-center justify-content-between">
      <div>
        <h4 className="fw-bold">{title}</h4>
      </div>

      <div>
        <button
          className="btn btn-dark btn-hover flex align-items-center gap-2"
          onClick={onButtonClick}
        >
          <span> {buttonName}</span>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="sm" />
        </button>
      </div>
    </div>
  );
};

export default POHeader;
