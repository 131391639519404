import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

interface SearchInputProps {
  placeholder: string;
  width?: string;
}
const SearchInput = (props: SearchInputProps) => {
  const { placeholder, width } = props;
  return (
    <div className="search-container" style={{ width: width }}>
      <input
        type="search"
        className="search-input outline-none border rounded-3 form-control text-sm"
        placeholder={placeholder}
      />
      <FontAwesomeIcon
        icon={faSearch}
        color="#9F9F9F"
        className="mr-2 search-icon"
      />
    </div>
  );
};

export default SearchInput;
