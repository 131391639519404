import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";

import { ROUTES } from "../../navigators/Routes";
import { images } from "../../assets";

import VerticalInput from "../../components/VerticalInput/VerticalInput";

import "./style.css";

const RecordPayment = () => {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(ROUTES.PURCHASE_ORDERS);
  };

  return (
    <div className="p-3 bg-white w-9 flex flex-column gap-3">
      {/* Header */}
      <div className="flex justify-content-between align-items-center">
        <h4 className="fw-bold">Bill Number-0001</h4>
        <div className="flex gap-3">
          <Button className="bg-transparent border text-sm border-2 text-secondary flex gap-2 align-items-center rounded-3">
            <FontAwesomeIcon icon={faFilePdf} />
            Download
          </Button>
          <Button
            className="bg-transparent text-sm rounded-3 border-danger text-danger px-3"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>

      {/* What's Next? */}
      <div className="flex justify-content-between align-items-center">
        <div className="flex gap-3 w-7 align-items-center">
          <img src={images.CardMan} alt="" height={95} width={95} />
          <div className="flex flex-column justify-content-center gap-2">
            <div className="text-lg fw-bold">WHAT’S NEXT?</div>
            <div className="text-sm">
              Record your payment and track the status of your order.
            </div>
          </div>
        </div>
      </div>

      {/* Bill Info */}
      <div className="p-3 bg-light rounded-4">
        <h2 className="fw-semibold text-lg mb-4">Bill</h2>
        <div className="flex justify-content-between gap-3 mb-3">
          <div className="w-25">
            <VerticalInput
              label="Bill Number"
              value={"0001"}
              placeHolder=""
              inputClass="bg-body-secondary"
            />
          </div>
          <div className="w-25">
            <VerticalInput
              label="Total Payment"
              value={"₹1395.00"}
              placeHolder=""
              inputClass="bg-body-secondary"
            />
          </div>
          <div className="w-25">
            <VerticalInput
              label="Payment Made"
              value={"₹1000.00"}
              placeHolder=""
            />
          </div>
          <div className="w-25">
            <VerticalInput
              label="Balance Due"
              value={"₹1000.00"}
              placeHolder=""
              inputClass="bg-body-secondary"
            />
          </div>
        </div>
        <div className="flex justify-content-between gap-4">
          <div className="w-25">
            <VerticalInput
              label="Payment Date"
              value={"30/05/2024"}
              placeHolder=""
            />
          </div>
          <div className="w-25">
            <VerticalInput
              label="Payment Due Date"
              value={"30/05/2024"}
              placeHolder=""
            />
          </div>
          <div className="w-25">
            <VerticalInput label="Payment Mode" value={"UPI"} placeHolder="" />
          </div>
          <div className="w-25">
            <VerticalInput
              label="Payment Terms"
              value={"Due On Receipt"}
              placeHolder=""
              inputClass="bg-body-secondary"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-content-between px-3 text-sm rounded-3 p-2 bg-light">
        <div className="flex align-items-center text-secondary fw-bold text- w-5 gap-3">
          <input type="checkbox" />
          <span>Send a Payment Made email notification.</span>
        </div>
        <div className="px-2 w-3 p-1 flex align-items-center text-sm gap-2 w-2 border-2 border-dashed text-secondary bg-body-light rounded-3">
          <FontAwesomeIcon icon={faFilePdf} size="xl" />
          PO-00002
        </div>
      </div>

      <div className="flex gap-3">
        <div className="flex justify-content-center px-3 p-1 align-items-center text-sm gap-3 border rounded-3">
          <input type="checkbox" />
          <span>aayushmandora@gmail.com</span>
        </div>
        <div className="flex justify-content-center px-3 p-1 align-items-center text-sm gap-3 border rounded-3">
          <input type="checkbox" />
          <span>thisisarshadq@gmail.com</span>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex gap-3 justify-content-end">
        <Button
          variant=""
          className="rounded-3 hover:bg-primary text-sm w-2 bg-dark text-white"
        >
          Save
        </Button>
        <Button variant="" className="rounded-3 cancel w-2 text-sm">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default RecordPayment;
