import { Table, Button, Image } from "react-bootstrap";
import { images } from "../../assets";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../navigators/Routes";
const Details = () => {
  const location = useLocation();
  return (
    <Table bordered>
      <thead>
        <tr className="fw-semibold">
          <td>Vendor Name</td>
          <td className="text-right">Email Address</td>
          <td className="text-center">Contact Number</td>
        </tr>
      </thead>
      <tbody>
        <tr style={{ borderBottom: "10px solid #EFEFEF" }}>
          <td className="w-6">
            <div className="d-flex align-items-center">
              <Image
                src="placeholder.png"
                style={{
                  width: "53.15px",
                  height: "52.28px",
                  marginRight: "10px",
                }}
              />
              <div className="w-100 flex justify-content-between align-items-center gap-1">
                {/* <Dropdown
                  placeholder={"Type or select an item"}
                  containerClassName="w-100"
                /> */}
                RK Retail Corp
                <div className="flex gap-1">
                  <img src={images.more} alt="" />
                  <img src={images.cancle} alt="" />
                </div>
              </div>
              {/* <Form.Control
                    type="text"
                    placeholder="Type or select an item"
                  /> */}
            </div>
          </td>
          <td className="text-right">
            {/* <Form.Control type="email"/> */}
            thisisarshd@gmail.com
          </td>
          <td className="text-center">
            {/* <Form.Control type="text" placeholder="0.00" /> */}
            +91 8523101045
          </td>
          {location.pathname === ROUTES.NEW_PURCHASE_ORDERS && (
            <td>
              <Button variant="" className="text-danger fw-bold text-xl">
                X
              </Button>
            </td>
          )}
        </tr>
      </tbody>
    </Table>
  );
};

export default Details;
