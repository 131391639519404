import { useState } from "react";

import Button from "../../components/Button";

import HorizontalInputField from "../../components/HorizontalInputField/HorizontalInputField";
import NavTabs from "../../components/NavTabs/NavTabs";

const Profile = () => {
  const [activeTab, setActiveTab] = useState<string>("Store Profile");

  const [contactPN, setContactPN] = useState("");
  const [storeName, setStoreName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [eMail, setEMail] = useState("");
  const [retailAddress, setRetailAddress] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [gst, setGst] = useState("");
  const [signature, setSignature] = useState("");

  const [accountNo, setAccountNo] = useState("");
  const [confirmAccountNo, setConfirmAccountNo] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  return (
    <div className="p-3 flex flex-column gap-4">
      <h4 className="fw-bold">Profile</h4>
      <NavTabs
        tabs={["Store Profile", "Bank Details"]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {/* Store Profile */}
      {activeTab === "Store Profile" ? (
        <div className="flex flex-column gap-3 mt-2">
          <div className="w-9">
            <HorizontalInputField
              label="Contact Person Name* :"
              value={contactPN}
              handleChange={setContactPN}
              placeHolder="Add Contact Person Name"
            />
          </div>
          <div className="w-9">
            <HorizontalInputField
              label="Store Name* :"
              value={storeName}
              handleChange={setStoreName}
              placeHolder="Add Store Name"
            />
          </div>
          <div className="w-9">
            <HorizontalInputField
              label="Phone Number* :"
              value={phoneNo}
              handleChange={setPhoneNo}
              placeHolder="Add Phone Number"
            />
          </div>
          <div className="w-9">
            <HorizontalInputField
              label="Email Address* :"
              value={eMail}
              handleChange={setEMail}
              placeHolder="Add Your Store Email Address"
            />
          </div>
          <div className="w-9">
            <HorizontalInputField
              label="Retail Store Address* :"
              value={retailAddress}
              handleChange={setRetailAddress}
              placeHolder="Add Your Retail Store Address"
            />
          </div>
          <div className="w-9">
            <HorizontalInputField
              label="Delivery Address* :"
              value={deliveryAddress}
              handleChange={setDeliveryAddress}
              placeHolder="Add Your Delivery Address"
            />
          </div>
          <div className="w-9">
            <HorizontalInputField
              label="Registered Company Name* :"
              value={companyName}
              handleChange={setCompanyName}
              placeHolder="Add Your Company Registered Name"
            />
          </div>
          <div className="w-9">
            <HorizontalInputField
              label="GSTIN :"
              value={gst}
              handleChange={setGst}
              placeHolder="Add Your GST Number"
            />
          </div>
          {/* Store Logo */}
          <div className="w-9 flex justify-content-between mt-2">
            <label className="text-body-secondary text-sm fw-bold w-4">
              Store Logo* :
            </label>
            <div className="flex text-sm text-secondary align-items-center gap-3 w-100">
              <div className="py-5 bg-light border rounded-3 w-3 text-center">
                Upload +
              </div>
              <div>
                Images must be PNG or JPEG, recommended 1:1 (1024 x 1024 pixels)
                or 4:3 (640 x 480 Pixels) aspect ratios.
              </div>
            </div>
          </div>
          <div className="w-9">
            <HorizontalInputField
              label="Authorized Signature* :"
              value={signature}
              handleChange={setSignature}
              placeHolder="Add Your Signature Name"
            />
          </div>
          <div className="w-9 flex justify-content-end mt-2">
            <div className="flex text-sm text-secondary align-items-center gap-3 w-9">
              <div className="py-5 bg-light border rounded-3 w-3 text-center">
                Upload +
              </div>
              <div>
                Images must be PNG or JPEG, recommended 1:1 (1024 x 1024 pixels)
                or 4:3 (640 x 480 Pixels) aspect ratios.
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Bank Details
        <div className="flex flex-column gap-3 mt-2">
          <div className="w-9">
            <HorizontalInputField
              label="Account Number* :"
              value={accountNo}
              handleChange={setAccountNo}
              placeHolder="Add Account Number"
            />
          </div>
          <div className="w-9">
            <HorizontalInputField
              label="Confirm Account Number* :"
              value={confirmAccountNo}
              handleChange={setConfirmAccountNo}
              placeHolder="Re-Enter Your Account Number"
            />
          </div>
          <div className="w-9 flex gap-2">
            <div className="w-100">
              <HorizontalInputField
                label="IFSC Code* :"
                value={ifscCode}
                handleChange={setIfscCode}
                placeHolder="Add IFSC Code"
                inputClass="w-75 ml-2"
              />
            </div>
            <Button variant="primary" className="rounded-3 text-sm w-25">
              Fetch Bank Details
            </Button>
          </div>
          <div className="w-9">
            <HorizontalInputField
              label="Bank Name* :"
              value={bankName}
              handleChange={setBankName}
              placeHolder="Add Bank Name"
            />
          </div>
          <div className="w-9">
            <HorizontalInputField
              label="Branch Name* :"
              value={branchName}
              handleChange={setBranchName}
              placeHolder="Add Brand Name"
            />
          </div>
        </div>
      )}

      <div className="mt-3 bottom-0 flex justify-content-end">
        <Button variant="dark" className="rounded-3 text-sm p-2 px-4">
          Save & Update
        </Button>
      </div>
    </div>
  );
};

export default Profile;
