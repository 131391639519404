import { Table, Image } from "react-bootstrap";

import { images } from "../../assets";

import "./style.css";

const ProductTable = () => {
  return (
    <Table className="p-table transparent-table mt-3">
      <thead className={`bg-light text-sm rounded-t-3`}>
        <tr>
          <td rowSpan={3}>Item Details</td>
          <td>Purchase Order Quantity</td>
          <td>Rate (in ₹)</td>
          <td>Amount (in ₹)</td>
        </tr>
      </thead>
      <tbody>
        <tr className="border-bottom table-tr">
          <td className="w-5">
            <div className="d-flex align-items-center">
              <Image
                src={images.Rice}
                height={45}
                width={45}
                className="border rounded-3 border-2"
                style={{
                  marginRight: "10px",
                }}
              />
              <div className="row">
                <div className="w-100 text-sm flex align-items-center justify-content-between gap-1">
                  Organic Sonamasuri Brown Rice
                </div>
              </div>
            </div>
          </td>
          <td>
            <input type="number" className="form-control w-50" value={"2"} />
          </td>
          <td>
            <input type="number" className="form-control w-50" value={350} />
          </td>
          <td className="font-bold">700</td>
        </tr>
      </tbody>
    </Table>
  );
};

const ProductTableView = () => {
  return (
    <Table className="p-table transparent-table mt-3">
      <thead className={`bg-light text-sm`}>
        <tr>
          <td>Item Details</td>
          <td>Purchase Order Quantity</td>
          <td>Amount (in ₹)</td>
        </tr>
      </thead>
      <tbody>
        <tr className="border-bottom table-tr">
          <td className="w-5">
            <div className="d-flex align-items-center">
              <Image
                src={images.Rice}
                height={45}
                width={45}
                className="border rounded-3 border-2"
                style={{
                  marginRight: "10px",
                }}
              />
              <div className="row">
                <div className="w-100 text-sm fw-bold flex align-items-center justify-content-between gap-1">
                  Organic Sonamasuri Brown Rice
                </div>
              </div>
            </div>
          </td>
          <td>
            <input type="number" className="form-control w-50" value={"2"} />
          </td>
          <td className="font-bold">700</td>
        </tr>
      </tbody>
    </Table>
  );
};

export { ProductTable, ProductTableView };
