import ApiService from "../services/ApiService";
import { END_POINTS } from "../utils/ApiEndpoints";

const BASE_API_URL = ApiService.getApiUrl();

export const addVendor = (payload: any) => {
  const URL = BASE_API_URL + END_POINTS.ADD_VENDOR;
  return ApiService.callPostApi(URL, payload);
};

export const addContactDetails = (payload: any) => {
  const URL = BASE_API_URL + END_POINTS.ADD_CONTACT_DETAILS;
  return ApiService.callPostApi(URL, payload);
};

export const getVendors = async () => {
  const URL = BASE_API_URL + END_POINTS.GET_VENDORS;
  return ApiService.callGetApi(URL);
};
