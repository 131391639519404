import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Button, CloseButton, Form, Table, Dropdown } from "react-bootstrap";
import { Image } from "react-bootstrap";

import DatePicker from "react-datepicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faClock,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { ROUTES } from "../../navigators/Routes";

import { images } from "../../assets";

import VerticalInput from "../../components/VerticalInput/VerticalInput";
import SearchInput from "../../components/SearchInput/SearchInput";
import Dropdown1 from "../../components/Dropdown1/Dropdown1";
import DeletePopUp from "../../components/DeletePopUp/DeletePopUp";
import "./style.css";

const NewPurchaseOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;
  const title = state.title;
  const typeOfForm = state.type;

  const [poId, setPOId] = useState<string>("");
  const [billNo, setBillNo] = useState<string>("");
  const [referenceNO, setReferenceNO] = useState("");
  const [orderDate, setOrderDate] = useState(new Date());
  const [eDeliveryDate, setEDeliveryDate] = useState(new Date());

  const [eMail, setEMail] = useState();
  const [contactNo, setContactNo] = useState();

  const [contactPN, setContactPN] = useState("Arshad Qureshi");
  const [address, setAddress] = useState(
    "14, Rajasthan Society, Shahalam Gate, Shahalam, Ahmedabad, Gujarat - 380028"
  );

  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [typeOfSale, setTypeOfSale] = useState("-");

  const [qty, setQty] = useState();

  const [paymentTerms, setPaymentTerms] = useState("Due on Receipt");
  const [paymentMode, setPaymentMode] = useState("UPI");
  const [discount, setDiscount] = useState("%");
  const [showDelete, setShowDelete] = useState(false);

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  const handleClose = () => {
    if (state.title === "New Purchase Order") {
      navigate(ROUTES.PURCHASE_ORDERS);
    } else {
      navigate(ROUTES.BILLS);
    }
  };

  const handleClickSaveAs = () => {
    navigate(ROUTES.ALL_PURCHASE_ORDERS);
  };

  const sendMail = () => {
    navigate(ROUTES.SENDMAIL);
  };

  return (
    <div className="p-3">
      <div className="flex justify-content-between align-items-center">
        <h4 className="mb-4 fw-bold">{title}</h4>
        <CloseButton onClick={handleClose} />
      </div>
      {/* General Info */}
      <div className="p-3 bg-light rounded-4">
        <h6 className="fw-semibold mb-4 text-lg">General Information</h6>
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="Purchase Order#"
              value={poId}
              handleChange={setPOId}
              placeHolder="PO - 0007"
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="Bill Number#"
              value={billNo}
              handleChange={setBillNo}
              placeHolder="PO - 0007"
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="Reference Number"
              value={referenceNO}
              handleChange={setReferenceNO}
              placeHolder="210111121"
            />
          </div>
          <div className="col-md-6 col-lg-3 text-sm">
            <label className="form-label fw-semibold text-muted">
              Order Date
            </label>
            <DatePicker
              selected={orderDate}
              onChange={(date: any) => setOrderDate(date)}
              selectsStart
              startDate={orderDate}
              dateFormat="dd/MM/yyyy"
              popperPlacement="bottom-start"
              customInput={<Form.Control />}
              className="border-none shadow-none text-sm"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 col-lg-3 text-sm">
            <label className="form-label fw-semibold text-muted">
              Expected Delivery Date
            </label>
            <DatePicker
              selected={eDeliveryDate}
              onChange={(date: any) => setEDeliveryDate(date)}
              selectsStart
              startDate={eDeliveryDate}
              dateFormat="dd/MM/yyyy"
              popperPlacement="bottom-start"
              customInput={<Form.Control />}
              className="border-none shadow-none text-sm "
            />
          </div>
        </div>
      </div>

      {/* Vendor Info */}
      {typeOfForm !== "Customer" && (
        <div className="p-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h6 className="fw-semibold text-lg">Vendor Information</h6>
            <a href="#/" className="text-xs fw-semibold text-decoration-none">
              Add new Vendor?
            </a>
          </div>
          <div className="flex justify-content-between gap-4">
            <div className="w-100 row text-sm ">
              <label className="form-label fw-semibold text-muted">Name</label>
              <SearchInput placeholder="Search your vendor name, contact name, n...." />
            </div>
            <div className="w-100">
              <VerticalInput
                label="E-Mail"
                value={eMail}
                handleChange={setEMail}
                placeHolder="abc@gmail.com"
              />
            </div>
            <div className="w-100">
              <VerticalInput
                label="Contact Number"
                value={contactNo}
                handleChange={setContactNo}
                placeHolder="+91 9192111023"
              />
            </div>
          </div>
        </div>
      )}

      {/* Delivery Address */}
      {typeOfForm === "NewPO" && (
        <div className="p-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h6 className="fw-semibold text-lg">Delivery Address</h6>
            <a href="#/" className="text-xs fw-semibold text-decoration-none">
              Change Address
            </a>
          </div>
          <div className="flex justify-content-between gap-4">
            <div className="w-100">
              <VerticalInput
                label="Contact Person Name"
                value={contactPN}
                handleChange={setContactPN}
                placeHolder="Name"
              />
            </div>
            <div className="w-100">
              <VerticalInput
                label="Address"
                value={address}
                handleChange={setAddress}
                placeHolder="+91 9192111023"
              />
            </div>
          </div>
        </div>
      )}

      {/* Customer Info */}
      {typeOfForm === "Customer" && (
        <div className="p-3">
          <h6 className="fw-semibold mb-4 text-lg">Customer Information</h6>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <VerticalInput
                label="Contact Number"
                value={contactNo}
                handleChange={setContactNo}
                placeHolder="+91 9192111023"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <VerticalInput
                label="Customer ID"
                value={customerId}
                handleChange={setCustomerId}
                placeHolder="+91 9192111023"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <VerticalInput
                label="Name"
                value={customerName}
                handleChange={setCustomerName}
                placeHolder="Customer Name"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <VerticalInput
                label="E-Mail"
                value={eMail}
                handleChange={setEMail}
                placeHolder="abc@gmail.com"
              />
            </div>
          </div>
        </div>
      )}

      {/* Type of Sale */}
      {typeOfForm === "Customer" && (
        <div className="p-3">
          <h6 className="fw-semibold mb-4 text-lg">Sales Type</h6>
          <div className="row gap-2 w-2">
            <div className="text-sm text-secondary fw-bold">Type of Sale</div>
            <Dropdown1
              title={typeOfSale}
              titles={["In Store", "Online"]}
              placeholderText=""
              onSelect={setTypeOfSale}
            />
          </div>
        </div>
      )}

      {/* Product Info */}
      <div className="p-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h6 className="fw-semibold text-lg">Product Information</h6>
          <a href="#/" className="text-xs fw-semibold text-decoration-none">
            Add new Product?
          </a>
        </div>
        <div className="flex gap-3">
          <SearchInput placeholder="Search for existing product" />
          <div>
            <input
              type="number"
              value={qty}
              onChange={(e: any) => {
                setQty(e.target.value);
              }}
              className="form-control text-sm"
              placeholder={"Qty"}
            />
          </div>
          <Button variant="dark" className="rounded-3 text-sm">
            Add to Bill <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
        <Table className="p-table transparent-table mt-3">
          <thead className={`bg-light text-sm rounded-t-3`}>
            <tr>
              <td rowSpan={3}>Item Details</td>
              <td>Purchase Order Quantity</td>
              <td>Rate (in ₹)</td>
              <td>Amount (in ₹)</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr className="border-bottom table-tr">
              <td className="w-5">
                <div className="d-flex align-items-center">
                  <Image
                    src={images.Rice}
                    height={45}
                    width={45}
                    className="border rounded-3 border-2"
                    style={{
                      marginRight: "10px",
                    }}
                  />
                  <div className="row">
                    <div className="w-100 text-sm flex align-items-center justify-content-between gap-1">
                      Organic Sonamasuri Brown Rice
                    </div>
                    <div className="text-xs text-secondary fw-bold">
                      Stock in hand : 25
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <input
                  type="number"
                  className="form-control w-50"
                  value={"2"}
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control w-50"
                  value={350}
                />
              </td>
              <td className="font-bold">700</td>
              <td>
                <FontAwesomeIcon icon={faTrash} color="red" />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      {/* Payment terms */}
      <div className="flex p-3 justify-content-between mb-7">
        <div className="w-50 flex flex-column gap-4">
          <h6 className="fw-semibold text-lg">Payment</h6>
          <div className="flex gap-3">
            <div className="row gap-2 w-50">
              <div className="text-sm text-secondary fw-bold">
                Payment Terms
              </div>
              <Dropdown1
                title={paymentTerms}
                titles={["Due on Receipt", "net 15", "net 30", "net 45"]}
                placeholderText=""
                onSelect={setPaymentTerms}
              />
            </div>
            <div className="row gap-2 w-50">
              <div className="text-sm text-secondary fw-bold">Payment Mode</div>
              <Dropdown1
                title={paymentMode}
                titles={["UPI", "Credit Card", "Debit Card", "Net Banking"]}
                placeholderText=""
                onSelect={setPaymentMode}
              />
            </div>
          </div>
          <div className="text-sm text-secondary">
            <span className="fw-bold mr-2">Note:</span>All products ordered must
            meet the specified specifications and quality requirements as
            detailed in purchase order.
          </div>
        </div>
        <div className="d-flex flex-column gap-3 text-sm p-3 px-4 bg-light rounded-3 w-50">
          <div className="d-flex justify-content-between">
            <span className="detail-text fw-bold">
              Sub Total{" "}
              <span className="text-xs fw-normal">(inclusive GST)</span>
            </span>
            <span className="detail-amount">₹1,500.00</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span className="detail-text fw-bold">
              GST{" "}
              <span className="text-xs fw-normal">(Goods & Service Tax)</span>
            </span>
            <div className="d-flex justify-content-between align-items-center w-6">
              <div className="w-7 flex align-items-center">
                <input
                  type="number"
                  value={5}
                  className="p-2 rounded-start w-100 border outline-none text-center"
                />
                <span className="border p-2 px-3 rounded-end">%</span>
              </div>
              <span className="ms-2 detail-amount">₹75.00</span>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span className="detail-text fw-bold">Discount</span>
            <div className="d-flex justify-content-between align-items-center w-6">
              <div className="w-7 flex align-items-center">
                <input
                  type="number"
                  value={2}
                  className="p-2 rounded w-100 border outline-none text-center"
                />
                <div className="py-2">
                  <Dropdown1
                    title={discount}
                    titles={["%", "₹"]}
                    onSelect={setDiscount}
                    placeholderText=""
                  />
                </div>
              </div>
              <span className="ms-2 detail-amount">(₹30.00)</span>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span className="detail-text fw-bold">Adjustments</span>
            <div className="d-flex justify-content-between align-items-center w-6">
              <div className="w-7 flex align-items-center">
                <input
                  type="number"
                  value={5}
                  className="p-2 rounded-start w-100 border outline-none text-center"
                />
                <span className="border p-2 px-3 rounded-end">₹</span>
              </div>
              <span className="ms-2 detail-amount">(₹75.00)</span>
            </div>
          </div>
          <div className="d-flex fw-bold justify-content-between text-base border-top pt-3">
            <span>Total Amount</span>
            <span>₹1,395.00</span>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="p-2 b-bar w-10 px-3 bg-white rounded-3 shadow flex justify-content-between align-items-center text-base">
        <div className="flex gap-3 fw-bold">
          <div className="text-secondary">Total</div>
          <div>₹1,395.00</div>
        </div>
        <div className="flex gap-4">
          <Button
            variant=""
            className="rounded-3 cancel px-4 text-sm"
            onClick={() => {
              setShowDelete(true);
            }}
          >
            Cancel
          </Button>
          <Button variant="" className="rounded-3 draft text-sm">
            Save as Draft
          </Button>
          {state === "New Purchase Order" ? (
            <Button
              variant=""
              className="rounded-3 hover:bg-primary text-sm bg-dark text-white"
              onClick={sendMail}
            >
              Add To Bill & Send
            </Button>
          ) : (
            <Dropdown>
              <Dropdown.Toggle
                variant="dark"
                id="dropdown-basic"
                className="flex gap-2 align-items-center"
              >
                Save as
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className="flex gap-3 align-items-center text-secondary"
                  onClick={handleClickSaveAs}
                >
                  <FontAwesomeIcon icon={faClock} size="sm" /> Pending
                </Dropdown.Item>
                <Dropdown.Item
                  className="flex gap-3 align-items-center text-primary"
                  onClick={handleClickSaveAs}
                >
                  <FontAwesomeIcon icon={faCircleCheck} size="sm" />
                  Paid
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>

      <DeletePopUp
        btnName={"Save as Draft"}
        show={showDelete}
        body={
          "Any unsaved changes will be lost. Do you want to save them in drafts?"
        }
        handleClose={handleCloseDelete}
      />
    </div>
  );
};

export default NewPurchaseOrder;
