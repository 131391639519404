import { Dispatch, SetStateAction } from "react";
import { Nav } from "react-bootstrap";

interface NavTabsProps {
  tabs: Array<string>;
  activeTab: string | undefined;
  setActiveTab: Dispatch<SetStateAction<string>>;
}
const NavTabs = (props: NavTabsProps) => {
  const { tabs, setActiveTab, activeTab } = props;
  const handleSelect = (selectedTab: any) => {
    setActiveTab(selectedTab);
  };
  return (
    <Nav
      variant="tabs"
      defaultActiveKey="all"
      onSelect={handleSelect}
      className="transaction-tabs"
    >
      {tabs.map((item: string, i: number) => {
        return (
          <Nav.Item key={i}>
            <Nav.Link
              eventKey={item}
              active={activeTab === item}
              style={{
                color: activeTab === item ? "blue" : "black",
                border: "none",
                fontWeight: activeTab === item ? "bold" : "normal",
                borderBottom: activeTab === item ? "3px solid blue" : "none",
              }}
            >
              {item}
            </Nav.Link>
          </Nav.Item>
        );
      })}
    </Nav>
  );
};

export default NavTabs;
