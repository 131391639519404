import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";

type Props = {
  titles: Array<String>;
  Name: String;
};
const FilterButton = (props: Props) => {
  const { titles, Name } = props;
  const [selectedItem, setSelectedItem] = useState<String>(Name);
  const handleSelect = (item: String) => {
    setSelectedItem(item);
  };
  return (
    <DropdownButton
      variant=""
      className="ml-3 border border-2 rounded"
      title={
        <>
          <FontAwesomeIcon icon={faFilter} className="mr-2" />
          {selectedItem}
        </>
      }
    >
      {titles.map((item, i) => {
        return (
          <Dropdown.Item
            key={i}
            className="item"
            onClick={() => handleSelect(item)}
          >
            {item}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

export default FilterButton;
