import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Image } from "react-bootstrap";
import { images } from "../../assets";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../navigators/Routes";
const VendorTransaction = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="flex mt-3 flex-column gap-6">
      {location.pathname === "/Vendor-Details" && (
        <div className="flex mb-3 justify-content-between">
          <h5 className="fw-bold">Total Purchase Orders</h5>
          <span
            className="flex gap-1 align-items-center text-sm fw-semibold"
            style={{ color: "#005D58" }}
          >
            <FontAwesomeIcon icon={faPlus} /> Add Purchase Order
          </span>
        </div>
      )}
      <div
        className="flex  border border-2 rounded-4 position-relative p-2 px-3 flex-column gap-2"
        style={{ backgroundColor: "#FAFAFA" }}
      >
        <div className="flex justify-content-between align-items-center">
          {/* Index,Image and ProductName and SubProduct name */}
          <div
            className="flex align-items-center gap-4"
            style={{ width: "30%" }}
          >
            <div className="fw-bold">1</div>
            <div className="flex align-items-center gap-3">
              <Image
                // className={item.productSubCategory.length > 0 ? "ml-5" : "ml-6"}
                src={images.Rice}
                rounded
                width={"80px"}
                height={"80px"}
                style={{
                  objectFit: "contain",
                }}
              />
              <div className="flex flex-column gap-3">
                <span
                  style={{
                    fontSize: "12.69px",
                    color: "#8B8C89",
                  }}
                >
                  Product Name
                </span>
                <h3 className="fw-semibold text-xl">Basmatic Rice</h3>
              </div>
            </div>
          </div>

          {/*VendorName Quantity, ReorderLevel,Purchase Price, RetailPrice */}
          <div
            className="flex justify-content-between gap-4"
            style={{ width: "70%" }}
          >
            <table
              className="table table-borderless text-center transparent-table"
              style={{ width: "90%" }}
            >
              <tbody>
                <tr>
                  <td
                    className="text-sm"
                    style={{ color: "#8B8C89", fontWeight: "400" }}
                  >
                    Vendor Name
                  </td>
                  <td
                    className="text-sm"
                    style={{ color: "#8B8C89", fontWeight: "400" }}
                  >
                    Order Quantity
                  </td>
                  <td
                    className="text-sm"
                    style={{ color: "#8B8C89", fontWeight: "400" }}
                  >
                    Reorder Level
                  </td>
                  <td
                    className="text-sm"
                    style={{ color: "#8B8C89", fontWeight: "400" }}
                  >
                    Purchase Price
                  </td>
                  <td
                    className="text-sm"
                    style={{ color: "#8B8C89", fontWeight: "400" }}
                  >
                    Retail Price
                  </td>
                </tr>
                <tr className="fw-semibold">
                  <td
                    className="text-xl"
                    style={{ color: "#000000", fontWeight: "600" }}
                  >
                    24 Mantra Organic
                  </td>
                  <td
                    className="text-xl"
                    style={{ color: "#000000", fontWeight: "600" }}
                  >
                    35
                  </td>
                  <td
                    className="text-xl"
                    style={{ color: "#000000", fontWeight: "600" }}
                  >
                    10
                  </td>
                  <td
                    className="text-xl"
                    style={{ color: "#000000", fontWeight: "600" }}
                  >
                    {"₹ 170"}
                  </td>
                  <td
                    className="text-xl"
                    style={{ color: "#000000", fontWeight: "600" }}
                  >
                    {"₹ 195"}
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <button
                className="p-1 px-4 rounded-3 text-white border-none outline-none"
                style={{
                  backgroundColor: "#38B000",
                  fontSize: "12px",
                  width: "100px",
                }}
              >
                Paid
              </button>
            </div>
          </div>
        </div>
        {/* Delivery Status and EDIT/DELETE Button */}
        <div className="flex justify-content-between">
          <div style={{ width: "2.5%" }}></div>
          <div
            className="flex justify-content-between align-items-center p-2 rounded-3"
            style={{
              width: "88%",
              backgroundColor: "#EEEEEE",
              border: "2px solid #E0E0E0",
            }}
          >
            <div
              className="fw-semibold"
              style={{ fontSize: "13px", color: "#7B7B7B" }}
            >
              PO No : 0211
            </div>
            <div
              className="flex justify-content-between align-items-center"
              style={{ width: "60%" }}
            >
              <div className="flex align-items-center gap-3">
                <Image src={images.delivered} height={31} width={31} />5 Apr
              </div>
              <div
                style={{
                  width: "107px",
                  height: "0px",
                  border: "2px solid #000000",
                }}
              ></div>
              <div className="flex align-items-center gap-3">
                <Image src={images.fastdelivery} height={31} width={31} />6 Apr
              </div>
              <div
                style={{
                  width: "107px",
                  height: "0px",
                  border: "2px solid #000000",
                }}
              ></div>
              <div className="flex align-items-center gap-3">
                <Image src={images.packagedelivered} height={25} width={25} />8
                Apr
              </div>
            </div>
            <div className="fw-semibold text-sm">Order Dispatched</div>
          </div>
          <div
            className="d-flex gap-4 justify-content-end align-items-center"
            style={{ width: "9.5%" }}
          >
            <Image
              src={images.Edit}
              height={20}
              width={20}
              onClick={() => {
                navigate(ROUTES.EDIT_PURCHASE_ORDER);
              }}
            />
            <Image src={images.Delete} height={20} width={20} />
          </div>
        </div>
        <div className="complete-tag">RECEIVED</div>
      </div>
      <div
        className="flex  border border-2 rounded-4 position-relative p-2 px-3 flex-column gap-2"
        style={{ backgroundColor: "#FAFAFA" }}
      >
        <div className="flex justify-content-between align-items-center">
          {/* Index,Image and ProductName and SubProduct name */}
          <div
            className="flex align-items-center gap-4"
            style={{ width: "30%" }}
          >
            <div className="fw-bold">2</div>
            <div className="flex align-items-center gap-3">
              <Image
                // className={item.productSubCategory.length > 0 ? "ml-5" : "ml-6"}
                src={images.Rice}
                rounded
                width={"80px"}
                height={"80px"}
                style={{
                  objectFit: "contain",
                }}
              />
              <div className="flex flex-column gap-3">
                <span
                  style={{
                    fontSize: "12.69px",
                    color: "#8B8C89",
                  }}
                >
                  Product Name
                </span>
                <h3 className="fw-semibold text-xl">Basmatic Rice</h3>
              </div>
            </div>
          </div>

          {/*VendorName Quantity, ReorderLevel,Purchase Price, RetailPrice */}
          <div
            className="flex justify-content-between gap-4"
            style={{ width: "70%" }}
          >
            <table
              className="table table-borderless text-center transparent-table"
              style={{ width: "90%" }}
            >
              <tbody>
                <tr>
                  <td
                    className="text-sm"
                    style={{ color: "#8B8C89", fontWeight: "400" }}
                  >
                    Vendor Name
                  </td>
                  <td
                    className="text-sm"
                    style={{ color: "#8B8C89", fontWeight: "400" }}
                  >
                    Order Quantity
                  </td>
                  <td
                    className="text-sm"
                    style={{ color: "#8B8C89", fontWeight: "400" }}
                  >
                    Reorder Level
                  </td>
                  <td
                    className="text-sm"
                    style={{ color: "#8B8C89", fontWeight: "400" }}
                  >
                    Purchase Price
                  </td>
                  <td
                    className="text-sm"
                    style={{ color: "#8B8C89", fontWeight: "400" }}
                  >
                    Retail Price
                  </td>
                </tr>
                <tr className="fw-semibold">
                  <td
                    className="text-xl"
                    style={{ color: "#000000", fontWeight: "600" }}
                  >
                    24 Mantra Organic
                  </td>
                  <td
                    className="text-xl"
                    style={{ color: "#000000", fontWeight: "600" }}
                  >
                    35
                  </td>
                  <td
                    className="text-xl"
                    style={{ color: "#000000", fontWeight: "600" }}
                  >
                    10
                  </td>
                  <td
                    className="text-xl"
                    style={{ color: "#000000", fontWeight: "600" }}
                  >
                    {"₹ 170"}
                  </td>
                  <td
                    className="text-xl"
                    style={{ color: "#000000", fontWeight: "600" }}
                  >
                    {"₹ 195"}
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <button
                className="p-1 rounded-3 text-white border-none outline-none"
                style={{
                  backgroundColor: "#F02D2D",
                  fontSize: "12px",
                  width: "100px",
                }}
              >
                Payment Due
              </button>
            </div>
          </div>
        </div>
        {/* Delivery Status and EDIT/DELETE Button */}
        <div className="flex justify-content-between">
          <div style={{ width: "2.5%" }}></div>
          <div
            className="flex justify-content-between align-items-center p-2 rounded-3"
            style={{
              width: "88%",
              backgroundColor: "#EEEEEE",
              border: "2px solid #E0E0E0",
            }}
          >
            <div
              className="fw-semibold"
              style={{ fontSize: "13px", color: "#7B7B7B" }}
            >
              PO No : 0211
            </div>
            <div
              className="flex justify-content-between align-items-center"
              style={{ width: "60%" }}
            >
              <div className="flex align-items-center gap-3">
                <Image src={images.delivered} height={31} width={31} />5 Apr
              </div>
              <div
                style={{
                  width: "107px",
                  height: "0px",
                  border: "2px solid #000000",
                }}
              ></div>
              <div
                className="flex align-items-center gap-3"
                style={{ opacity: "0.3" }}
              >
                <Image src={images.fastdelivery} height={31} width={31} />6 Apr
              </div>
              <div
                style={{
                  width: "107px",
                  height: "0px",
                  border: "2px solid #000000",
                  opacity: "0.3",
                }}
              ></div>
              <div
                className="flex align-items-center gap-3"
                style={{ opacity: "0.3" }}
              >
                <Image src={images.packagedelivered} height={25} width={25} />8
                Apr
              </div>
            </div>
            <div className="fw-semibold text-sm" style={{ color: "#8B8C89" }}>
              Purchase Order Created
            </div>
          </div>
          <div
            className="d-flex gap-4 justify-content-end align-items-center"
            style={{ width: "9.5%" }}
          >
            <Image
              src={images.Edit}
              height={20}
              width={20}
              onClick={() => {
                navigate(ROUTES.EDIT_PURCHASE_ORDER);
              }}
            />
            <Image src={images.Delete} height={20} width={20} />
          </div>
        </div>
        <div
          className="complete-tag"
          style={{ opacity: "0.3", backgroundColor: "#A8A8A8" }}
        >
          ON PROCESS
        </div>
      </div>
    </div>
  );
};

export default VendorTransaction;
