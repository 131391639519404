import React, { useState } from "react";

const ToggleButton: React.FC = () => {
  const [isToggled, setIsToggled] = useState(false);

  // Styling based on Figma properties
  const toggleStyles: React.CSSProperties = {
    width: "48px",
    height: "22px",
    backgroundColor: isToggled ? "#0d6efd" : "#6c757d",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: isToggled ? "flex-end" : "flex-start",
    padding: "2px",
    cursor: "pointer",
  };

  const circleStyles: React.CSSProperties = {
    width: "20px",
    height: "18px",
    backgroundColor: "white",
    borderRadius: "50%",
    transition: "transform 1s ease",
  };

  const toggle = () => setIsToggled(!isToggled);

  return (
    <div style={toggleStyles} onClick={toggle}>
      <div style={circleStyles} />
    </div>
  );
};

export default ToggleButton;
