import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../navigators/Routes";

import NavTabs from "../../components/NavTabs/NavTabs";
import SearchInput from "../../components/SearchInput/SearchInput";
import {
  POHeader,
  DateRangePicker,
  TransactionTable,
} from "../../components/PurchaseOrderComponents/index";

const PurchaseOrders = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("All Transactions");

  const handleNewPurchaseOrder = () => {
    navigate(ROUTES.NEW_PURCHASE_ORDERS, {
      state: { title: "New Purchase Order", type: "NewPO" },
    });
  };

  return (
    <div>
      <POHeader
        title="Purchase Orders"
        buttonName={"Create Purchase Order"}
        onButtonClick={handleNewPurchaseOrder}
      />
      <div className="pl-4 flex flex-column gap-3 mt-3">
        <NavTabs
          tabs={[
            "All Transactions",
            "On Process",
            "Received",
            "Cancelled",
            "Draft",
          ]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className="flex justify-content-between">
          <SearchInput
            placeholder="Search by product name, vendor name, purchase order number...."
            width="40%"
          />
          <DateRangePicker />
        </div>
        <TransactionTable />
        <div
          className="d-flex justify-content-around align-items-center"
          style={{ width: "415px", height: "40px" }}
        >
          <div className="text-center p-1 border px-2 bg-primary-subtle  rounded-3">
            Total ₹11,180
          </div>
          <div className="text-center p-1 border px-2 bg-success-subtle  rounded-3">
            Paid ₹990
          </div>
          <div className="text-center p-1 border px-2  bg-danger-subtle  rounded-3">
            Pending ₹3,540
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrders;
