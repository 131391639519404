interface VerticalInputProps {
  label?: string;
  value: any;
  handleChange?: any;
  placeHolder?: string;
  type?: string;
  inputClass?: string;
}
const VerticalInput = (props: VerticalInputProps) => {
  const { label, value, handleChange, placeHolder, type, inputClass } = props;
  return (
    <div className="text-sm">
      <label className="form-label fw-semibold text-muted">{label}</label>
      <input
        type={type}
        value={value}
        onChange={() => {
          handleChange();
        }}
        className={`form-control text-sm ${inputClass}`}
        placeholder={placeHolder}
      />
    </div>
  );
};

export default VerticalInput;
