interface HorizontalInputFieldProps {
  label?: string;
  value: any;
  handleChange?: any;
  placeHolder?: string;
  type?: string;
  inputClass?: string;
}
const HorizontalInputField = (props: HorizontalInputFieldProps) => {
  const { label, value, handleChange, placeHolder, type, inputClass } = props;
  return (
    <div className="flex justify-content-between align-items-center">
      <label className="text-body-secondary text-sm fw-bold w-4">{label}</label>
      <input
        type={type}
        value={value}
        onChange={() => {
          handleChange();
        }}
        className={`form-control text-sm ${inputClass}`}
        placeholder={placeHolder}
      />
    </div>
  );
};

export default HorizontalInputField;
