import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../Button";

interface Props {
  show: boolean;
  handleClose?: () => void;
  handleSave?: () => void;
  body: React.ReactNode;
  loading?: boolean;
  btnName: String;
  size?: "sm" | "lg" | "xl";
}

const DeletePopUp = (props: Props) => {
  const { show, handleClose, handleSave, body, loading, size, btnName } = props;
  return (
    <Modal
      size={size}
      show={show}
      onHide={handleClose}
      backdrop="static"
      centered
    >
      <Modal.Body className="fw-bold text-xl flex justify-content-center px-7">
        <div className="text-center p-3">{body}</div>
      </Modal.Body>
      <Modal.Footer className="border-top-none flex gap-3">
        <Button
          variant=""
          className="text-xs cancel-btn px-3"
          onClick={handleClose}
          isLoading={loading}
        >
          Don't Save
        </Button>
        <Button
          className="text-xs bg-dark text-white hover:bg-primary border-dark hover:border-primary px-3"
          onClick={handleSave}
          disabled={loading}
        >
          {btnName}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeletePopUp;
