import { useState } from "react";

import { Dropdown } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faUsers } from "@fortawesome/free-solid-svg-icons";

import NavTabs from "../../components/NavTabs/NavTabs";
import { BillHeader, BillsTable } from "../../components/BillsComponents/index";
import SearchInput from "../../components/SearchInput/SearchInput";
import { DateRangePicker } from "../../components/PurchaseOrderComponents";

import "./style.css";

const Bills = () => {
  const [activeTab, setActiveTab] = useState("All Bills");
  return (
    <div className="p-3 flex flex-column gap-3">
      <BillHeader title="All Bills" buttonName={"Create New Bill"} />
      <div className="mt-3">
        <NavTabs
          tabs={["All Bills", "Pending", "Paid", "Overdue", "Cancelled"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
      {/* Search input and Filters */}
      <div className="flex justify-content-between align-items-center">
        <SearchInput
          placeholder="Search by product name, vendor name, purchase order number...."
          width="30%"
        />

        <DateRangePicker />

        <div className="flex gap-3 align-items-center">
          Bill for
          <Dropdown>
            <Dropdown.Toggle variant="dark" id="dropdown-basic">
              <FontAwesomeIcon icon={faCartShopping} size="sm" /> For Purchase
              Order
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="flex gap-3 align-items-center">
                <FontAwesomeIcon icon={faCartShopping} size="sm" /> For Purchase
                Order
              </Dropdown.Item>
              <Dropdown.Item className="flex gap-3 align-items-center">
                <FontAwesomeIcon icon={faUsers} size="sm" />
                For Customers
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <BillsTable />
      <div className="d-flex gap-3 align-items-center">
        <div className="text-center p-1 px-3 text-sm bg-black bg-opacity-75 text-white fw-bold rounded-3">
          Total Outstanding : ₹11,180
        </div>
        <div className="text-center p-1 px-3 text-sm text-secondary bg-body-secondary fw-bold rounded-3">
          Due Today : ₹990
        </div>
        <div className="text-center p-1 px-3 text-sm text-secondary bg-body-secondary fw-bold rounded-3">
          Due Within 30 Days : ₹3000
        </div>
        <div className="text-center p-1 px-3 text-sm text-secondary bg-body-secondary fw-bold rounded-3">
          Overdue Bills : ₹3000
        </div>
      </div>
    </div>
  );
};

export default Bills;
