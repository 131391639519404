import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import "./style.css";

type Props = {
  titles: Array<string>;
  title: string;
  placeholderText: string;
  onSelect: (value: string) => void;
};
const Dropdown1 = (props: Props) => {
  const [Value, setValue] = useState("");
  const { titles, title, placeholderText, onSelect } = props;

  const handleSelect = (e: any) => {
    setValue(e);
    onSelect(e);
  };

  return (
    <Dropdown className={`w-100`} onSelect={handleSelect}>
      <Dropdown.Toggle
        variant=""
        className="w-100 flex border justify-content-between text-secondary align-items-center"
      >
        {Value ? Value : title}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100">
        {titles.map((item, i) => {
          return (
            <Dropdown.Item key={i} eventKey={item} className="item">
              {item}
            </Dropdown.Item>
          );
        })}
        {placeholderText !== "" && (
          <input
            type="text"
            placeholder={placeholderText}
            className="w-100 p-1 px-3 outline-none border-none"
            onChange={(e) => handleSelect(e.target.value)}
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Dropdown1;
