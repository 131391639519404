import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

// import {
//   RecaptchaVerifier,
//   signInWithPhoneNumber,
//   Auth,
//   ConfirmationResult,
//   UserCredential,
// } from "firebase/auth";
// import { auth } from "../../env/firebase.config";

import "./login.css";

import Button from "../../components/Button";
import Input from "../../components/Input";

import { loginUser } from "../../services/AuthService";

import {
  getUserId,
  toast,
  updateUserAuthenticationStatus,
} from "../../utils/helper";
import { ROUTES } from "../../navigators/Routes";

// declare const window: any;

interface LoginProps {}

const Login = (props: LoginProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    let id;
    let getData = async () => {
      id = await getUserId();
      if (id) {
        navigate(ROUTES.MAIN, { replace: true });
      }
    };
    getData();
  }, [navigate]);

  const [formValues, setFormValues] = useState({
    emailOrMobile: "",
    password: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    emailOrMobile: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  // const [phoneNumber, setPhoneNumber] = useState<string>("");
  // const [otp, setOtp] = useState<string>("");
  // const [verificationId, setVerificationId] = useState<string | null>(null);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));

    setErrorMessages((prevState) => ({
      ...prevState,
      [e.target.id]: "",
    }));
  };

  // const setUpRecaptcha = (auth: Auth) => {
  //   if (!window.recaptchaVerifier) {
  //     window.recaptchaVerifier = new RecaptchaVerifier(
  //       auth,
  //       "recaptcha-container",
  //       {
  //         size: "invisible",
  //         callback: () => {
  //           console.log("Recaptcha verified");
  //         },
  //       }
  //     );
  //   }
  // };

  // const handleSendOtp = async () => {
  //   setLoading(true);
  //   setUpRecaptcha(auth);
  //   const appVerifier = window.recaptchaVerifier;

  //   try {
  //     const confirmationResult: ConfirmationResult =
  //       await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
  //     setVerificationId(confirmationResult.verificationId);
  //     alert("OTP sent!");
  //   } catch (error) {
  //     console.error("Error during OTP sending:", error);
  //     alert("Failed to send OTP. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleVerifyOtp = async () => {
  //   if (!verificationId) {
  //     alert("Verification ID not found. Please request OTP first.");
  //     return;
  //   }

  //   try {
  //     const credential = window.firebase.auth.PhoneAuthProvider.credential(verificationId, otp);
  //     const result: UserCredential = await auth.signInWithCredential(credential);
  //     alert("Phone number verified and user logged in!");
  //     navigate(ROUTES.MAIN, { replace: true });
  //   } catch (error) {
  //     console.error("Error during OTP verification:", error);
  //     alert("Failed to verify OTP. Please try again.");
  //   }
  // };

  const validateInputs = () => {
    let isValid = true;
    let errorMEssages = {
      emailOrMobile: "",
      password: "",
    };

    if (
      !formValues.emailOrMobile ||
      (formValues.emailOrMobile && formValues.emailOrMobile.trim().length === 0)
    ) {
      isValid = false;
      errorMEssages.emailOrMobile =
        "Please enter your email address or mobile number";
    } else if (
      !formValues.emailOrMobile.includes("@") &&
      isNaN(parseInt(formValues.emailOrMobile))
    ) {
      isValid = false;
      errorMEssages.emailOrMobile =
        "Please enter a valid email address or mobile number";
    }

    if (
      !formValues.password ||
      (formValues.password && formValues.password.trim().length === 0)
    ) {
      isValid = false;
      errorMEssages.password = "Please enter your password";
    }

    setErrorMessages(errorMEssages);

    return isValid;
  };

  const onBtnPress = () => {
    setLoading(true);
    const isValid = validateInputs();
    if (isValid) {
      login();
    } else {
      setLoading(false);
    }
  };

  const login = async () => {
    try {
      const preparedData = {
        email: formValues.emailOrMobile,
        password: formValues.password,
      };
      const response = await loginUser(preparedData);
      console.log("response :>> ", response);

      if (response && response.statusCode === 400) {
        toast.error(response.message);
        return;
      }

      await updateUserAuthenticationStatus(true, JSON.stringify(response.data));

      navigate(ROUTES.MAIN, { replace: true });
    } catch (error) {
      console.log("error in login :>> ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-body-tertiary">
      <div
        className="shadow p-5 rounded bg-white"
        style={{
          width: "40vw",
          // height: "60vh",
        }}
      >
        <h2>Login</h2>
        <p>to access dashboard</p>
        <form>
          <div className="mb-3">
            <Input
              type="text"
              id="emailOrMobile"
              aria-describedby="emailOrMobileHelp"
              placeholder="Email address or mobile number"
              onChange={onInputChange}
              value={formValues.emailOrMobile}
              error={errorMessages.emailOrMobile}
            />
            <Input
              isPassword
              id="password"
              aria-describedby="password"
              placeholder="Enter password"
              className="mt-3"
              onChange={onInputChange}
              value={formValues.password}
              error={errorMessages.password}
            />
          </div>
          <Button
            type="submit"
            className="w-100 mt-3"
            onClick={onBtnPress}
            isLoading={loading}
          >
            Login
          </Button>
          {/* OTP code 
          <div>
            <h3>Phone Number OTP Authentication</h3>
            <input
              type="text"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <button onClick={handleSendOtp} disabled={loading}>
              {loading ? "Sending OTP..." : "Send OTP"}
            </button>

            {verificationId && (
              <>
                <input
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <button onClick={handleVerifyOtp}>Verify OTP</button>
              </>
            )}

            <div id="recaptcha-container"></div>
          </div>*/}
        </form>

        <div className="mt-3">
          <NavLink
            to="/messages"
            className="text-decoration-none text-end d-flex justify-content-end"
          >
            Forgot Password?
          </NavLink>
          <div className="mt-3 d-flex">
            <p className="me-1">Don't have an Urbanlyfe Account? </p>
            <NavLink to={ROUTES.REGISTER} className="text-decoration-none">
              Register Now
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
