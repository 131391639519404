import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import {
  faClipboard,
  faEnvelope,
  faFilePdf,
} from "@fortawesome/free-regular-svg-icons";

import SideBarAPO from "../../components/SideBarAPO/SideBarAPO";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import VerticalInput from "../../components/VerticalInput/VerticalInput";
import { ProductTableView } from "../../components/Vendo&ProductDetailsTable/ProductTable";
import Dropdown1 from "../../components/Dropdown1/Dropdown1";
import DeletePopUp from "../../components/DeletePopUp/DeletePopUp";
import RecordPayment from "./RecordPayment";

import { images } from "../../assets";
import { ROUTES } from "../../navigators/Routes";

const AllPurchaseOrders = () => {
  const navigate = useNavigate();

  const [billNo, setBillNo] = useState("0001");
  const [billDate, setBillDate] = useState("05-10-2024");
  const [billDueDate, setBillDueDate] = useState("05-10-2024");
  const [paymentTerms, setPaymentTerms] = useState("Due On Receipt");
  const [balanceDue, setBalanceDue] = useState("₹1395.00");
  const [total, setTotal] = useState("₹1395.00");

  const [name, setName] = useState("Arshad Qureshi");
  const [email, setEmail] = useState("abc@gmail.com");
  const [contactNo, setContactNo] = useState("+91 9192111023");

  const [discount, setDiscount] = useState("%");

  const [showDelete, setShowDelete] = useState(false);

  const [recordPayment, setRecordPayment] = useState(false);

  const handleClose = () => {
    setShowDelete(false);
  };

  const handleCancel = () => {
    navigate(ROUTES.PURCHASE_ORDERS);
  };

  return (
    <div className="flex gap-1">
      {/* SideBar */}
      <div className="w-3 vh-100">
        <SideBarAPO />
      </div>
      {/* Main */}
      {!recordPayment ? (
        <div className="p-3 bg-white w-9 flex flex-column gap-3">
          {/* Header */}
          <div className="flex justify-content-between align-items-center">
            <h4 className="fw-bold">PO-001</h4>
            <div className="flex gap-3">
              <Button className="bg-transparent border text-sm border-2 text-secondary flex gap-2 align-items-center rounded-3">
                <FontAwesomeIcon icon={faPen} />
                Edit
              </Button>
              <Button className="bg-transparent border text-sm border-2 text-secondary flex gap-2 align-items-center rounded-3">
                <FontAwesomeIcon icon={faFilePdf} />
                Download
              </Button>
              <Button
                className="bg-transparent text-sm rounded-3 border-danger text-danger px-3"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </div>

          {/* What's Next? */}
          <div className="flex justify-content-between align-items-center">
            <div className="flex gap-3 w-7 align-items-center">
              <img src={images.CardMan} alt="" height={95} width={95} />
              <div className="flex flex-column justify-content-center gap-2">
                <div className="text-lg fw-bold">WHAT’S NEXT?</div>
                <div className="text-sm">
                  Record your payment and create a receive to complete your
                  purchase.
                </div>
              </div>
            </div>
            <div className="flex gap-3 text-sm">
              Show PDF View <ToggleButton />
            </div>
          </div>

          {/* General Info */}
          <div className="p-3 bg-light rounded-4">
            <h2 className="fw-semibold text-lg mb-4">General Information</h2>
            <div className="flex justify-content-between gap-3 mb-3">
              <div className="flex flex-column text-sm w-25">
                <label className="form-label fw-semibold text-muted">
                  Bill Status
                </label>
                <div
                  className={`text-center rounded-3 pending border-warning border p-2 text-xs fw-bold`}
                >
                  Pending
                </div>
              </div>
              <div className="w-25">
                <VerticalInput
                  label="Bill Number"
                  value={billNo}
                  handleChange={setBillNo}
                  placeHolder=""
                />
              </div>
              <div className="w-25">
                <VerticalInput
                  label="Bill Date"
                  value={billDate}
                  handleChange={setBillDate}
                  placeHolder=""
                />
              </div>
              <div className="w-25">
                <VerticalInput
                  label="Bill Due Date"
                  value={billDueDate}
                  handleChange={setBillDueDate}
                  placeHolder=""
                />
              </div>
            </div>
            <div className="flex justify-content-between gap-4">
              <div className="flex flex-column text-sm w-25">
                <label className="form-label fw-semibold text-muted">
                  Order Status
                </label>
                <div
                  className={`text-center rounded-3 onProcess border-primary border p-2 text-xs fw-bold`}
                >
                  ON PROCESS
                </div>
              </div>
              <div className="w-25">
                <VerticalInput
                  label="Payment Terms"
                  value={paymentTerms}
                  handleChange={setPaymentTerms}
                  placeHolder=""
                />
              </div>
              <div className="w-25">
                <VerticalInput
                  label="Balance Due"
                  value={balanceDue}
                  handleChange={setBalanceDue}
                  placeHolder=""
                />
              </div>
              <div className="w-25">
                <VerticalInput
                  label="Total"
                  value={total}
                  handleChange={setTotal}
                  placeHolder=""
                />
              </div>
            </div>
          </div>

          {/* Vendor Info */}
          <div className="p-3">
            <h2 className="fw-semibold text-lg mb-4">Vendor Information</h2>
            <div className="flex justify-content-between gap-4">
              <div className="w-4">
                <VerticalInput
                  label="Name"
                  value={name}
                  handleChange={setName}
                  placeHolder=""
                />
              </div>
              <div className="w-4">
                <VerticalInput
                  label="E-Mail"
                  value={email}
                  handleChange={setEmail}
                  placeHolder=""
                />
              </div>
              <div className="w-4">
                <VerticalInput
                  label="Contact Number"
                  value={contactNo}
                  handleChange={setContactNo}
                  placeHolder=""
                />
              </div>
            </div>
          </div>

          {/* Product Info */}
          <div className="p-3">
            <h2 className="fw-semibold text-lg mb-4">Product Information</h2>
            <ProductTableView />
          </div>

          {/* Total's */}
          <div className="flex justify-content-end mb-7">
            <div className="d-flex flex-column gap-3 text-sm p-3 px-4 bg-light rounded-3 w-7 mb-3">
              <div className="d-flex justify-content-between">
                <span className="detail-text fw-bold">
                  Sub Total{" "}
                  <span className="text-xs fw-normal">(inclusive GST)</span>
                </span>
                <span className="detail-amount">₹1,500.00</span>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <span className="detail-text fw-bold">
                  GST{" "}
                  <span className="text-xs fw-normal">
                    (Goods & Service Tax)
                  </span>
                </span>
                <div className="d-flex justify-content-between align-items-center w-6">
                  <div className="w-7 flex align-items-center">
                    <input
                      type="number"
                      value={5}
                      className="p-2 rounded-start w-100 border outline-none text-center"
                    />
                    <span className="border p-2 px-3 rounded-end">%</span>
                  </div>
                  <span className="ms-2 detail-amount">₹75.00</span>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <span className="detail-text fw-bold">Discount</span>
                <div className="d-flex justify-content-between align-items-center w-6">
                  <div className="w-7 flex align-items-center">
                    <input
                      type="number"
                      value={2}
                      className="p-2 rounded w-100 border outline-none text-center"
                    />
                    <div className="py-2">
                      <Dropdown1
                        title={discount}
                        titles={["%", "₹"]}
                        onSelect={setDiscount}
                        placeholderText=""
                      />
                    </div>
                  </div>
                  <span className="ms-2 detail-amount">(₹30.00)</span>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <span className="detail-text fw-bold">Adjustments</span>
                <div className="d-flex justify-content-between align-items-center w-6">
                  <div className="w-7 flex align-items-center">
                    <input
                      type="number"
                      value={5}
                      className="p-2 rounded-start w-100 border outline-none text-center"
                    />
                    <span className="border p-2 px-3 rounded-end">₹</span>
                  </div>
                  <span className="ms-2 detail-amount">(₹75.00)</span>
                </div>
              </div>
              <div className="d-flex fw-bold justify-content-between text-base border-top pt-3">
                <span>Total Amount</span>
                <span>₹1,395.00</span>
              </div>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="p-2 w-7 ml-3 b-bar px-3 bg-white rounded-3 shadow flex justify-content-between align-items-center text-base">
            <div className="flex gap-3 fw-bold">
              <div className="text-secondary">Total</div>
              <div>₹1,395.00</div>
            </div>
            <div className="flex gap-4">
              <Button
                variant=""
                className="rounded-3 cancel px-4 text-sm"
                onClick={() => {
                  setShowDelete(true);
                }}
              >
                Cancel
              </Button>
              <Button
                variant=""
                className="rounded-3 bg-primary text-white text-sm"
              >
                Update Order Status <FontAwesomeIcon icon={faClipboard} />
              </Button>
              <Button
                variant=""
                className="rounded-3 hover:bg-primary text-sm bg-dark text-white"
                onClick={() => {
                  setRecordPayment(true);
                }}
              >
                Record Payment <FontAwesomeIcon icon={faEnvelope} />
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <RecordPayment />
      )}
      <DeletePopUp
        btnName={"Save as Draft"}
        show={showDelete}
        body={
          "Any unsaved changes will be lost. Do you want to save them in drafts?"
        }
        handleClose={handleClose}
      />
    </div>
  );
};

export default AllPurchaseOrders;
