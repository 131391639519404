import { useEffect, useState } from "react";

import { Button as PrimeReactButton } from "primereact/button";
import { Image } from "primereact/image";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowUp,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import { useFormik } from "formik";

import { images } from "../../assets";

import { colors } from "../../themes/colors";

import { Animations } from "../../animations";

import { ADD_BRAND_VALIDATION } from "../../utils/validations";
import { toast } from "../../utils/helper";

import Loader from "../../components/Loader";
import RenderLottie from "../../components/RenderLottie";
import DataOverview from "../../components/DataOverview";
import Input from "../../components/Input";
import Button from "../../components/Button";
import ActionPopup from "../../components/ActionPopup";
import Modal from "../../components/Modal";
import ViewBrand from "../../components/ViewBrand";
import {
  addBrand,
  deleteBrand,
  getBrands,
  updateBrand,
} from "../../controller/BrandsController";
import { uploadFile } from "../../controller/FilesController";

const Brands = () => {
  const [loading, setLoading] = useState(false);
  const [isSomethingWentWrong, setIsSomethingWentWrong] = useState(false);
  const [deletePopupVisible, setDeletePopupVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [brandsData, setBrandsData] = useState([]);
  const [totalBrands, setTotalBrands] = useState("");
  const [brandImage, setBrandImage] = useState<any>("");
  const [imageError, setImageError] = useState("");
  const [editBrandId, setEditBrandId] = useState("");
  const [EditBrandImage, setEditBrandImage] = useState({
    Image: "",
    Url: "",
    Value: false,
  });

  useEffect(() => {
    getUserBrands();
  }, []);

  const getUserBrands = async () => {
    setLoading(true);
    try {
      const response = await getBrands();
      if (response?.statusCode === 400) {
        toast.error(response?.message);
        return;
      }

      setBrandsData(response.data);
      setTotalBrands(response.data.length);
    } catch (error) {
      setIsSomethingWentWrong(true);
    } finally {
      setLoading(false);
    }
  };

  const imageUpload = async () => {
    let preparedImage = new FormData();
    preparedImage.append("file", brandImage);
    const response = await uploadFile(preparedImage);
    return response.key;
  };

  const { values, errors, touched, handleChange, handleSubmit, handleReset } =
    useFormik({
      initialValues: {
        brandName: "",
      },
      enableReinitialize: true,
      validationSchema: ADD_BRAND_VALIDATION,
      onSubmit: () => {
        if (imageError) {
          return;
        }
        handleSave();
      },
    });

  const handleSave = async () => {
    try {
      setButtonLoading(true);
      let image;
      let preparedData;

      !EditBrandImage.Value && (image = await imageUpload());

      EditBrandImage.Value
        ? (preparedData = {
            name: values.brandName,
            image: EditBrandImage.Image,
          })
        : (preparedData = {
            name: values.brandName,
            image,
          });

      let brandFunction;

      if (isEditMode) {
        brandFunction = updateBrand;
      } else {
        brandFunction = addBrand;
      }

      const response = await brandFunction(preparedData, editBrandId);

      if (response?.statusCode === 400) {
        toast.error(response?.message);
        return;
      }

      handleCloseModal();
      if (isEditMode) {
        setIsEditMode(false);
        setEditBrandId("");
      }
      getUserBrands();
    } catch (error) {
      console.log("error in saving data :>> ", error);
    } finally {
      setButtonLoading(false);
    }
  };

  const validateInputs = () => {
    if (!brandImage) {
      setImageError("Required");
    } else {
      setImageError("");
    }

    handleSubmit();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    handleReset(() => {});
    setBrandImage("");
  };

  const onDelete = async () => {
    try {
      const response = await deleteBrand(editBrandId);
      if (response?.statusCode === 400) {
        toast.error(response?.message);
        return;
      }

      toast.success("Brand deleted successfully");

      await getUserBrands();
    } catch (error) {
      console.log("error in deleting brand :>> ", error);
    } finally {
      setDeletePopupVisible(false);
      setEditBrandId("");
    }
  };

  const hideDeletePopup = () => {
    setDeletePopupVisible(false);
    setEditBrandId("");
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newImages: Blob[] = Array.from(files);
      setBrandImage(newImages[0]);
    }
  };

  const onDeleteImage = () => {
    setBrandImage("");
    if (EditBrandImage) {
      setEditBrandImage({ Value: false, Image: "", Url: "" });
    }
  };

  const handleEdit = async (item: any) => {
    try {
      setIsEditMode(true);
      setEditBrandId(item.id);
      handleChange("brandName")(item?.name);

      const imageUrl = item?.imageUrl;

      setBrandImage(imageUrl);
      setEditBrandImage({ Value: true, Url: item.imageUrl, Image: item.image });

      setShowModal(true);
    } catch (error) {
      console.log("error in editing category :>> ", error);
      toast.error("Something went wrong");
      handleReset(() => {});
      setEditBrandId("");
      setIsEditMode(false);
      setBrandImage("");
      setShowModal(false);
    }
  };

  const handleDelete = (id: string) => {
    setEditBrandId(id);
    setDeletePopupVisible(true);
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      {isSomethingWentWrong ? (
        <RenderLottie
          animationData={Animations.SomethingWentWrong}
          title="Something Went Wrong"
          message="Please try again later or contact support for assistance."
        />
      ) : (
        <div className="px-4 py-3">
          <h4 className="fw-semibold">Brands</h4>

          <div className="d-flex mt-5">
            <DataOverview
              title="Total Brands"
              value={totalBrands}
              icon={images.CheckList}
              iconBgColor={colors.greenGradient}
              title2="Total Products"
              value2={"10"}
              icon2={images.SingleProduct}
              iconBgColor2={colors.blueGradient}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center	my-5">
            <div className="search-container w-50">
              <input
                type="search"
                className="search-input outline-none border rounded-3 p-2 px-3"
                placeholder="Search Brand"
              />
              <FontAwesomeIcon
                icon={faSearch}
                color="#9F9F9F"
                className="mr-2 search-icon"
              />
            </div>

            <Button
              variant="dark"
              onClick={() => setShowModal(true)}
              className="ml-3"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" color="#ECECEC" />
              <span
                style={{
                  color: "#ECECEC",
                }}
              >
                Add Brand
              </span>
            </Button>
          </div>

          <div>
            {brandsData.length === 0 ? (
              <RenderLottie
                animationData={Animations.NoDataFound}
                title="No Brand Found"
                message="Please add a new brand to get started"
              />
            ) : (
              <ViewBrand
                data={brandsData}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            )}
          </div>
        </div>
      )}
      <ActionPopup
        visible={deletePopupVisible}
        title=""
        message="Do you want to delete the brand?"
        confirmText="Yes"
        cancelText="No"
        onConfirm={onDelete}
        onCancel={hideDeletePopup}
      />

      <Modal
        show={showModal}
        title={isEditMode ? "Update Brand" : "Add Brand"}
        handleClose={handleCloseModal}
        handleSave={validateInputs}
        loading={buttonLoading}
        btnName={isEditMode ? "Edit Brand" : "Add Brand"}
        size="lg"
        body={
          <div className="d-flex justify-content-between">
            <div className="w-50">
              <Input
                label="Brand Name"
                placeholder="Enter a brand name"
                name="brandName"
                value={values.brandName}
                onChange={handleChange}
                error={
                  touched.brandName && errors.brandName ? errors.brandName : ""
                }
                autoFocus
              />
            </div>

            <div>
              {!brandImage ? (
                <div>
                  <label
                    htmlFor="image-upload"
                    className="border-dashed border-light-subtle rounded d-flex flex-column justify-content-center align-items-center bg-white"
                    style={{
                      height: "200px",
                      cursor: "pointer",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      id="image-upload"
                      style={{ visibility: "hidden" }}
                    />
                    <FontAwesomeIcon icon={faCloudArrowUp} size="2xl" />
                    <p className="w-75 text-center text-secondary">
                      Drag and drop your image here or click to upload
                    </p>
                  </label>
                  {imageError && (
                    <small className="text-danger">{imageError}</small>
                  )}
                </div>
              ) : (
                <div className="image-upload">
                  <div className="image-preview">
                    <div className="">
                      <Image
                        // src={URL.createObjectURL(brandImage)}
                        src={
                          EditBrandImage.Value
                            ? brandImage
                            : URL.createObjectURL(brandImage)
                        }
                        alt={`Brand`}
                        width="300"
                        height="200"
                        preview
                        style={{
                          objectFit: "contain",
                        }}
                      />
                      <PrimeReactButton
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger p-button-outlined p-button-sm p-button-text"
                        onClick={onDeleteImage}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Brands;
