import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { ROUTES } from "../../navigators/Routes";

import { toast } from "../../utils/helper";

import { Animations } from "../../animations";

import RenderLottie from "../../components/RenderLottie";
import Header from "../../components/Header";
import FilterButton from "../../components/Filterbutton/FilterButton";
import ViewAdjustments from "../../components/ViewAdjustments/ViewAdjustments";
import ActionPopup from "../../components/ActionPopup";
import {
  deleteAdjustment,
  getAdjustments,
} from "../../controller/AdjustmentsController";
import Loader from "../../components/Loader";

const Adjustments = () => {
  const [loading, setLoading] = useState(false);
  const [isSomethingWentWrong, setIsSomethingWentWrong] = useState(false);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [Adjustments, setAdjustments] = useState([]);
  const [Id, setId] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getAdjustments();

      if (response?.statusCode === 401) {
        setIsSomethingWentWrong(true);
        setAdjustments([]);
        return;
      }

      setAdjustments(response?.data);
    } catch (error: any) {
      console.log("Error in getAllAdjustments :>> ", error);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  console.log(Adjustments);
  const AddNewAdjustments = () => {
    navigate(ROUTES.ADD_ADJUSTMENTS, { state: { value: false, id: null } });
  };

  const onDelete = async () => {
    let response = await deleteAdjustment(Id);
    if (response.message === "Adjustment deleted successfully") {
      toast.success("Adjustment deleted successfully");
      setIsDeletePopup(false);
      getData();
    }
  };

  const handleDelete = (id: any) => {
    setIsDeletePopup(true);
    setId(id);
  };

  const cancleDelete = () => {
    setIsDeletePopup(false);
  };

  const onEdit = (id: any) => {
    navigate(ROUTES.ADD_ADJUSTMENTS, { state: { value: true, id: id } });
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      {isSomethingWentWrong ? (
        <RenderLottie
          animationData={Animations.SomethingWentWrong}
          title="Something Went Wrong"
          message="Please try again later or contact support for assistance."
        />
      ) : (
        <>
          <Header
            title="Inventory Adjustments"
            onButtonClick={AddNewAdjustments}
            Buttonname={"Add Adjustments"}
          />
          {Adjustments.length > 0 && (
            <>
              <div className="bg-light flex justify-content-between align-items-center p-3">
                <div className="search-container" style={{ width: "35%" }}>
                  <input
                    type="search"
                    className="search-input outline-none border rounded-3 p-2 px-3"
                    placeholder="Search adjustment by item name"
                  />
                  <FontAwesomeIcon
                    icon={faSearch}
                    color="#9F9F9F"
                    className="mr-2 search-icon"
                  />
                </div>
                <div className="flex gap-3">
                  <FilterButton
                    Name={"Filter By Type"}
                    titles={["All", "By Quantity", "By Value"]}
                  />
                  <FilterButton
                    Name={"Filter By Period"}
                    titles={[
                      "All",
                      "Today",
                      "This Week",
                      "This Month",
                      "This Quarter",
                      "This Year",
                      "Date Range",
                    ]}
                  />
                </div>
              </div>
              <div className="w-100 flex gap-2 p-2 fw-bold text-sm px-4 border-top text-center border-bottom mt-4 align-items-center fw-semibold">
                <div
                  className="flex justify-content-between"
                  style={{ width: "6%" }}
                >
                  <input type="checkbox" />
                  <span></span>
                </div>
                <div
                  className="flex align-items-center"
                  style={{ width: "40%" }}
                >
                  <span className="w-25">DATE</span>
                  <span className="w-25">REASON</span>
                  <span className="w-50 text-uppercase text-left ml-2">
                    Description
                  </span>
                </div>
                <div
                  className="flex align-items-center gap-1"
                  style={{ width: "48%" }}
                >
                  <span className="w-2">STATUS</span>
                  <span className="w-2">REFERENCE NUMBER</span>
                  <span className="w-2">TYPE</span>
                  <span className="w-2">CREATED TIME</span>
                  <span className="w-3">LAST MODIFIED TIME</span>
                </div>
                <div style={{ width: "6%" }}></div>
              </div>
            </>
          )}
          <div>
            {Adjustments.length === 0 ? (
              <RenderLottie
                animationData={Animations.NoDataFound}
                title="No Adjustments Found"
                message="Please add a new adjustment to get started"
              />
            ) : (
              <ViewAdjustments
                Data={Adjustments}
                onDelete={handleDelete}
                onEdit={onEdit}
              />
            )}
          </div>
        </>
      )}
      <ActionPopup
        visible={isDeletePopup}
        title=""
        message="Do you want to delete the Adjustments?"
        confirmText="Yes"
        cancelText="No"
        onConfirm={onDelete}
        onCancel={cancleDelete}
      />
    </>
  );
};

export default Adjustments;
